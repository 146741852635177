@import "config/bundle";

.standardNavSubContainer {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.nested {
  display: none;
}

.visible {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--color-white);
}

.standardNavSubBackButton {
  margin-top: var(--layout-header);

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.standardNavSub {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 150px;
  background: var(--color-white);
}

.collapsible {
  @include media-breakpoint-up(lg) {
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      // transition: var(--easing-custom) calc(var(--timing-slow) - 100ms) opacity;
      pointer-events: none;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 1;
      width: 100%;
      height: 50px;
      background: linear-gradient(180deg, rgba(var(--color-code-white), 0) 0%, rgba(var(--color-code-white), 1) 100%);
    }
  }
}

.open {
  height: auto;

  &::after {
    opacity: 0;
  }
}

.standardNavSubLink {
  composes: cleanButton from global;
  display: flex;
  width: 100%;
  min-height: 80px;
  align-items: center;
  border-bottom: 1px solid var(--color-black-30);
  font-size: 13px;
  line-height: 21px;
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-primary);
  padding: 10px max(env(safe-area-inset-right), 30px) 10px max(env(safe-area-inset-left), 30px);

  &:hover {
    background-color: var(--color-primary-10);
    color: var(--color-primary);
  }

  @include media-breakpoint-up(lg) {
    min-height: initial;
    border-bottom: 0;
    font-size: 13px;
    line-height: 21px;
    font-weight: var(--font-weight-regular);
    padding: 5px 0;

    &:hover {
      background-color: initial;
      color: var(--color-primary-60);
    }
  }
}

.standardNavSubExpander {
  composes: cleanButton from global;
  display: none;
  font-size: 13px;
  line-height: 21px;
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-primary);
  width: 100%;
  transform: translateY(100%);
  padding: 5px 0;
  margin-top: -20px;

  &:hover {
    color: var(--color-primary-60);
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }
}