.district {
    border-bottom: 1px solid var(--color-black-30);
}

.districtButton {
    composes: cleanButton from global;
    font-size: 13px;
    padding: 10px 0;
    font-weight: var(--font-weight-semi-bold);

    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.btnIcon {
    width: 13px;
    transition: transform var(--timing-normal) var(--easing-primary);
    path {
        stroke: var(--color-primary);
    }
}

.btnIconOpen {
    transform: rotate(45deg);
}

.departmentsList {
    composes: cleanList from global;
    // padding: 10px;
    padding-bottom: 10px;
}
