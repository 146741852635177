@import 'config/bundle';

.featured {
    margin: 20px 30px 30px;
    margin-left: max(env(safe-area-inset-left), 30px);
    margin-right: max(env(safe-area-inset-right), 30px);

    @include media-breakpoint-up(lg) {
        margin: 0 60px 50px;
    }
}

.headline {
    font-size: 18px;
    line-height: 28px;
    font-weight: var(--font-weight-semi-bold);

    @include media-breakpoint-up(lg) {
        font-size: 22px;
    }
}

.listWrapper {
    overflow: hidden;
}

.list {
    composes: cleanList from global;
    margin: 0 -25px 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-up(sm) {
        --grid-min-max: 220px;
        grid-template-columns: repeat(auto-fill, minmax(var(--grid-min-max), 1fr));
    }
    @include media-breakpoint-up(xl) {
        --grid-min-max: 260px;
    }
}

.item {
    display: flex;
    align-items: stretch;
    position: relative;

    @include media-breakpoint-down(sm) {
        &:nth-child(odd) .link {
            padding-right: 0;
        }
    }
}

.link {
    display: flex;
    align-items: center;
    padding: 5px 25px;
    flex: 1 1 auto;
    min-height: 56px;
    font-size: 13px;
    line-height: 21px;

    &:hover .icon {
        color: var(--color-primary);
    }

    @include media-breakpoint-up(lg) {
        padding: 10px 25px;
        min-height: 76px;
        font-size: 18px;
        line-height: 28px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: var(--color-black-30);
            pointer-events: none;
        }
    }
}
.icon {
    background-color: var(--color-primary-10);
    margin-right: 10px;
    font-size: 12px;
    flex-shrink: 0;
}
