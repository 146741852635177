@import 'config/bundle';

.root {
    display: none;
    @include media-breakpoint-up(lg) {
        padding-top: 10px;
        display: block;
        width: 100%;
        max-width: 100%;
    }
}

.title {
    padding: 5px 5px 5px 15px;
    color: var(--color-white-dimmed);
}

.selector {
    column-gap: 10px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--color-white-dimmed);
    padding: 10px 10px 10px 15px;
}

.closeBtn {
    composes: cleanButton from global;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: var(--color-green-30);
    border-radius: 4px;
    height: 30px;
    column-gap: 5px;
}

.departmentText,
.closeBtn {
    max-width: 130px;
    color: var(--color-white-dimmed);
}

.closeBtn {
    color: var(--color-black);
}

.closeBtnIcon {
    width: 24px;
}

.changeButton {
    margin-left: auto;
}
