@import 'config/bundle';

.root {
    position: relative;
    height: 100%;
}

.rootFocus {
    composes: root;
    box-shadow: var(--shadow-focus);
}

.inputWrapper {
    display: flex;
    height: 100%;
}

.form {
    padding: 0 10px;
    flex: 1;
    display: flex;
    align-items: center;
    background-color: transparent;
    column-gap: 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &.onBlur {
        box-shadow: 0px 0px 0px 1px var(--color-orange) inset;
        border: 1px solid var(--color-orange);
        border-right: none;
    }

    &.onFocus {
        border: 1px solid var(--color-primary-40);
        border-right: none;
        background-color: var(--color-primary-10);
    }

    &.isSuggestionsOpen {
        @include media-breakpoint-up(lg) {
            border-bottom-left-radius: 0px;
        }
    }
}

.input {
    width: 100%;
    font-size: 14px;
    letter-spacing: 0;
    padding: 5px;
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;

    &::placeholder {
        color: var(--color-primary);
        font-size: 13px;
        font-weight: var(--font-weight-semi-bold);
        text-transform: uppercase;
    }
}

.dropdownContent {
    padding: 20px 10px;
    max-height: calc(100vh - var(--layout-header) - 120px);
    overflow: auto;
    position: absolute;
    top: 100%;
    width: 100%;

    &.isOpen {
        display: block;
    }

    @include media-breakpoint-up(lg) {
        box-shadow: var(--shadow-focus);
        padding: 30px 20px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid var(--color-primary-40);
        border-top: 0;
        background-color: var(--color-primary-10);
        max-height: calc(100vh - var(--layout-header));
    }
}

.suggestions {
    composes: dropdownContent;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.countries {
    composes: dropdownContent;
    display: none;
    background-color: var(--color-primary-10);
    box-shadow: var(--shadow-focus);
    border: 1px solid var(--color-primary-40);
    border-top: 0;
    z-index: 1;

    @include media-breakpoint-up(lg) {
    }
}
