@import 'config/bundle';

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--layout-header);
    z-index: var(--z-index-high);
    padding: 0 15px;
    position: fixed;
    left: calc(env(safe-area-inset-left, 0) + 70px);
    top: 0;

    @include media-breakpoint-up(lg) {
        left: 0;
        position: initial;
        padding: 0;
        flex-shrink: 0;
        display: block;
        height: auto;
    }
}

.logo {
    position: relative;
    height: auto;
    width: 50px;

    @include media-breakpoint-up(lg) {
        width: 100%;
        padding: 52px;
    }
}
