@import 'config/bundle';

$notification-color-bg: var(--color-white);
$notification-color-ok: var(--color-green);
$notification-color-info: var(--color-primary);
$notification-color-warn: var(--color-yellow);
$notification-color-error: var(--color-error);

.notification {
    position: relative;
    margin-top: 10px;
    width: 95%;
    min-height: 80px;
    background-color: $notification-color-bg;
    box-shadow: var(--shadow-focus);
    padding: 20px 10px 10px 10px;
    padding-right: 20px;
    transform: translateX(110%);
    opacity: 0;
    transition: all var(--timing-normal) var(--easing-primary);
    border-bottom: 3px solid $notification-color-bg;

    @include media-breakpoint-up(sm) {
        min-height: 120px;
        line-clamp: 5;
    }
}

.notificationActive {
    composes: notification;
    opacity: 1;
    transform: translateX(0);
}

.success {
    border-bottom-color: $notification-color-ok;
}

.info {
    border-bottom-color: $notification-color-info;
}

.warn {
    border-bottom-color: $notification-color-warn;
}

.error {
    border-bottom-color: $notification-color-error;
}

.closeBtn {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: transparent;
    padding: 10px;
    border: none;

    &:focus {
        outline: none;
    }
}

.close {
    width: 14px;
    height: 14px;
}

.contentWrapper {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.icon {
    svg {
        width: 24px;
    }
}

.error {
    composes: icon;
    path {
        stroke: $notification-color-error;
    }
}

.success {
    composes: icon;
    path {
        stroke: $notification-color-ok;
    }
}

.info {
    composes: icon;
    path {
        stroke: $notification-color-info;
    }
}

.warn {
    composes: icon;
    path,
    circle {
        stroke: $notification-color-warn;
    }
}
