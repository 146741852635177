@import "config/bundle";

.arrow {
  width: 20px;
  height: 15px;
  margin-left: auto;
  flex-shrink: 0;
  padding-left: 5px;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.icon {
  display: block;
  flex-shrink: 0;
  width: 30px;
  margin-right: 5px;

  @include media-breakpoint-up(lg) {
    width: 24px;
    margin-right: 10px;
  }
}