@import 'config/bundle';

.service {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex: 0 1 calc(100% - var(--layout-logo) - 30px - var(--layout-header));
    padding-right: min(env(safe-area-inset-right), 30px);

    @include media-breakpoint-up(lg) {
        flex-basis: auto;
        padding-right: 0;
    }
}

.linkWrapper {
    position: relative;
    display: flex;
    align-items: stretch;
}
.link {
    appearance: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-size: 9.5px;
    line-height: 15px;
    font-weight: var(--font-weight-semi-bold);
    padding: 0 2.5vw;
    min-width: 55px;
    position: relative;
    white-space: nowrap;
    -webkit-tap-highlight-color: initial;

    &:hover {
        color: var(--color-white);
    }

    @include media-breakpoint-up(lg) {
        color: var(--color-primary);
        font-size: 13px;
        min-width: 90px;
        padding: 13px 7.5px 0;
        border-left: 1px solid var(--color-black-30);

        &:hover,
        &.active {
            color: var(--color-primary);
            background-color: var(--color-primary-10);

            .circle {
                background-color: var(--color-primary-20);
            }
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: -1px;
            width: calc(100% + 2px);
            z-index: var(--z-index-header);
            height: 4px;
            transition: opacity var(--easing-primary) var(--timing-quick);
            background-color: var(--color-primary);
            opacity: 0;
        }
    }
}
.active::after {
    opacity: 1;
}

.circle {
    display: block;
    position: relative;

    @include media-breakpoint-up(lg) {
        color: var(--color-primary);
        background-color: var(--color-primary-10);
        border-radius: 50%;
        height: 31px;
        width: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
    }
}
.circleText {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-weight-semibold);
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(var(--color-code-white), 0.5);
    background-color: var(--color-primary-80);
    transform: translateY(-1px);

    @include media-breakpoint-up(lg) {
        color: var(--color-primary);
        background-color: var(--color-primary-10);
        height: 31px;
        width: 31px;
        transform: translateY(0);
    }
}

.icon {
    display: block;
    width: 24px;

    @include media-breakpoint-up(lg) {
        width: 15px;
    }

    path {
        stroke: currentColor;
    }
}

.badge {
    --size: 15px;
    --radius: calc(var(--size) / 2);

    position: absolute;
    top: calc(var(--radius) * -1 + 2px);
    right: calc(var(--radius) * -1 + 2px);
    border-radius: var(--radius);
    background-color: var(--color-orange);
    padding: 3.5px 2.5px;
    font-size: 8px;
    line-height: 8px;
    min-width: var(--size);
    font-weight: var(--font-weight-bold);
    text-align: center;
    color: var(--color-white);
}

@include media-breakpoint-up(lg) {
    .search {
        display: none;
    }
}

.basketLinkWrapper {
    composes: linkWrapper;
    position: relative;
}

.loaderWrapper {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    position: relative;
    bottom: 5px;
}

.updatingBasket {
    opacity: 0.5;
}

.settingsLink {
    @include media-breakpoint-down(md) {
        display: none;
    }
}
