.invoice {
    padding: 30px 0;
    border-top: 1px solid var(--color-line);
}

.header {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 20px;
    margin-bottom: 20px;
}

.headerItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.headerText {
    line-height: 1;
}

.footer {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 30px;
}
