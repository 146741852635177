.countriesList {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
}

.selected {
    border: 1px solid var(--color-primary);
}

.deselected {
    opacity: 0.5;
    &:hover {
        opacity: 0.8;
    }
}
