@import 'config/bundle';

.root {
    width: calc(100% - 60px);
    margin: 10px 30px 30px;
    margin-left: max(env(safe-area-inset-left), 30px);
    margin-right: max(env(safe-area-inset-right), 30px);

    @include media-breakpoint-up(lg) {
        width: calc(100% - 120px);
        margin: 0 60px 60px;
    }
}

.loader,
.errorBox {
    margin-top: var(--grid-gutter-width);
}

.table {
    width: 100%;
    display: table;
    border-collapse: collapse;
}

.tableRow {
    display: table-row;
    border-bottom: 1px solid var(--color-black-30);
}
a.tableRow {
    cursor: pointer;
}

.tableCell {
    display: table-cell;
    padding: 18px 10px;
    min-width: 110px;

    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
}

.tableCellRegNumber {
    text-transform: uppercase;
    white-space: nowrap;
}

.tableHead {
    color: rgba(var(--color-code-black), 0.5);
    font-weight: var(--font-weight-semi-bold);
    text-transform: uppercase;
    font-size: 10px;
}

.empty {
    margin: 30px;

    @include media-breakpoint-up(lg) {
        width: calc(100% - 120px);
        margin: 30px 60px;
    }
}
