@import "config/bundle";

.mainNavOverlay {
  display: none;

  @include media-breakpoint-up(lg) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: var(--layout-aside);
    right: 0;
    z-index: var(--z-index-high);
    display: none;
    background-color: rgba(var(--color-code-black), 0.5);
  }
}

.mainNavOverlayContentArea {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: var(--color-white);
}

.mainNavOverLayScrollArea {
  padding-bottom: max(env(safe-area-inset-bottom), 50px);

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
}

.visible {
  display: block;
}

@include media-breakpoint-up(lg) {
  /* Sizes from Bff.DisplayMode */
  .large {
    max-width: var(--layout-aside-wide);
  }
  .medium {
    max-width: var(--layout-aside-medium);
  }
  .small {
    max-width: var(--layout-aside-small);
  }
}