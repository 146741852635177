@import 'config/bundle';

.navScrollArea {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  height: 100%;

  @supports (-ms-ime-align:auto) {
    @include media-breakpoint-up(lg) {
      /* Style the scrollbar to so windows machines done have ugly grey scrollbars on blue background */
      &::-webkit-scrollbar {
        width: 12px;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: rgba(var(--color-code-black), 0.3);
      }
    }
  }
}