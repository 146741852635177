@import 'config/bundle';

.root {
    position: relative;
    @include media-breakpoint-down(lg) {
        padding: 10px 20px;
        position: fixed;
        height: calc(100% - var(--layout-header));
        z-index: calc(var(--z-index-navigation) + 1);
        top: var(--layout-header);
        transform: translateY(-100%);
        left: 0;
        width: 100%;
        transition: transform var(--timing-slow) var(--easing-custom), opacity var(--timing-slow) var(--easing-custom);
        background: var(--color-white);
        &.isOpen {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @include media-breakpoint-up(lg) {
        width: 100%;
        padding: 20px 30px;
    }
}

.container {
    position: relative;
    height: 50px;
    align-items: center;
}

.searchForm {
    flex: 1;
    display: flex;
    align-items: center;
}

.suggestions {
    position: absolute;
    width: 100%;
    padding: 20px;
    background-color: var(--color-primary-10);
    top: 100%;
    left: 0;
    right: 0;
}
