.trigger {
    composes: cleanButton from global;
}

.linkItem {
    width: 100%;
    min-width: 300px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.linkIcon {
    width: 24px;
}

.iconStroke {
    composes: linkIcon;

    path {
        stroke: var(--color-primary);
    }
}

.iconFill {
    composes: linkIcon;

    path {
        fill: var(--color-primary);
    }
}

.iconStrokeMotor {
    composes: linkIcon;
    path {
        stroke: var(--color-primary);
    }
}
