@import 'config/bundle';

.catalogNav {
    --pane-width: 300px;
    --pane-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --pane-padding: 10px 17px;
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-top: var(--layout-header);
    background: var(--color-white);
    overflow: auto;

    @include media-breakpoint-up(lg) {
        left: var(--layout-aside);
        right: auto;
        width: var(--pane-width);
        padding-top: 0;
    }
}

.current {
    @include media-breakpoint-up(lg) {
        box-shadow: var(--pane-shadow);
    }
}

.branch {
    @include media-breakpoint-up(lg) {
        width: var(--pane-width);
        left: calc(var(--layout-aside) + var(--pane-width));
    }
}

.catalogNavPaneBackButton {
    @include media-breakpoint-up(lg) {
        padding: var(--pane-padding);
        margin-bottom: 25px;
        padding-right: 90px;
    }
}

.catalogNavPaneList {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

.catalogNavPaneLink {
    display: flex;

    @include media-breakpoint-up(lg) {
        border-top: 1px solid var(--color-background-blue);
        border-bottom: 1px solid var(--color-background-blue);
        padding: var(--pane-padding);
        line-height: 1.3;

        &:hover {
            background: var(--color-background-blue);
        }
    }
}

.catalogNavLinkIcon {
    width: 20px;
    height: 15px;
    margin-left: auto;
    flex-shrink: 0;
    padding-left: 5px;

    @include media-breakpoint-up(lg) {
        width: 15px;
        height: 11px;
    }
}

.active {
    @include media-breakpoint-up(lg) {
        background: var(--color-background-blue);
    }
}

.catalogNavPaneLeaf {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-top: var(--layout-header);
    background: var(--color-white);

    @include media-breakpoint-up(lg) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: calc(var(--layout-aside) + var(--pane-width));
        right: 5%;
        padding-top: 0;
        max-width: 900px;
        background: var(--color-white);
    }
}

.nested {
    @include media-breakpoint-up(lg) {
        left: calc(var(--layout-aside) + var(--pane-width) + var(--pane-width));
    }
}

.hidden {
    display: none;
}

.open {
    display: block;
}

.catalogNavPaneLeafList {
    composes: catalogNavPaneList;

    @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        gap: 15px;
        padding-top: 0;
        padding: var(--pane-padding);
    }
}

.catalogNavPaneImage {
    display: none;
    border: 1px solid var(--color-background-blue);

    @include media-breakpoint-up(lg) {
        display: block;
        width: 100%;
        padding-top: 53%;
        margin-bottom: 10px;
        background: var(--color-background-blue);
        position: relative;
    }
}

.catalogNavPaneImageInner {
    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.catalogNavLeafPaneLinkContainer {
    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}

.catalogNavLeafPaneLink {
    @include media-breakpoint-up(lg) {
        display: block;
        line-height: 1.15;
        font-weight: 500;
    }

    &:hover {
        @include media-breakpoint-up(lg) {
            opacity: 0.9;
        }
    }
}
