@import 'config/bundle';

.navHeader {
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    padding-left: max(env(safe-area-inset-left), 70px);
    padding-right: max(env(safe-area-inset-right), 70px);
    font-size: 18px;
    line-height: 1.3;
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-black);
    padding: 10px 70px;
    border-bottom: 1px solid var(--color-black-30);
    text-align: center;

    @include media-breakpoint-up(lg) {
        border-bottom: none;
        font-size: 10px;
        justify-content: flex-start;
        color: var(--color-black);
        text-transform: uppercase;
        letter-spacing: 0.6px;
        padding: 10px 75px 10px 60px;
        min-height: 100px;
        text-align: left;
    }
}

.navHeaderBackButton {
    composes: cleanButton from global;
    width: calc(100% - 120px);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @include media-breakpoint-up(lg) {
        left: initial;
        right: 0;
        width: 90px;
        height: 90px;

        &:hover .navHeaderBackButtonIconContainer {
            background-color: var(--color-primary-60);
        }
    }
}

.navHeaderBackButtonIconContainer {
    width: 40px;
    height: 40px;
    position: absolute;
    left: max(env(safe-area-inset-left), 30px);
    top: 55%;
    transform: translateY(-50%);
    background-color: var(--color-primary-10);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);

    @include media-breakpoint-up(lg) {
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--color-primary);
        color: var(--color-white);
        transition: var(--easing-primary) background-color 100ms;
    }
}

.navHeaderBackButtonIcon {
    width: 20px;
    height: 15px;
    flex-shrink: 0;
    transform: rotate(180deg);

    @include media-breakpoint-up(lg) {
        transform: none;
    }
}

.iconChevron {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.iconClose {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}
