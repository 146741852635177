@import "config/bundle";

.mainNavParentLink {
  composes: cleanButton from global;
  display: flex;
  width: 100%;
  min-height: 80px;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--color-black-30);
  font-size: 13px;
  line-height: 21px;
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-primary);
  padding: 10px max(env(safe-area-inset-right), 30px) 10px max(env(safe-area-inset-left), 30px);

  &:hover {
    background-color: var(--color-primary-10);
    color: inherit;
  }

  @include media-breakpoint-up(lg) {
    color: var(--color-white);
    border-bottom: 0;
    min-height: 50px;
    padding: 10px 20px;

    &:hover {
      background-color: var(--color-primary-80);
    }
  }
}

.selected {
  @include media-breakpoint-up(lg) {
    &,
    &:hover {
      background-color: rgb(0, 54, 113);
    }
  }
}

.mainNavParentLinkIcon {
  display: block;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 5px;
  stroke-width: 1.5px;
  color: var(--color-primary);

  @include media-breakpoint-up(lg) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: var(--color-white);
  }
}

.mainNavParentLinkArrow {
    width: 20px;
    height: 15px;
    margin-left: auto;
    flex-shrink: 0;
    padding-left: 5px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
}