@import 'config/bundle';

.root {
    position: relative;
    background: var(--color-primary-10);
    padding-bottom: 3px;
    box-shadow: var(--shadow-focus);
    z-index: calc(var(--z-index-above-normal) + 1);

    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;
        z-index: var(--z-index-normal);
        box-shadow: var(--shadow-border-15-primary);
    }
}

.topSection {
    display: flex;
    column-gap: 15px;
    padding: 15px 0;
}

.topSectionExpertToggle {
    padding: 23px 0;
}

.bannerHeaderBackButton {
    display: none !important;

    @include media-breakpoint-up(lg) {
        display: block !important;
    }
}

.bannerHeaderLink {
    display: grid;
    align-items: center;
    grid-template-columns: min-content auto;
    gap: 15px;
}

.bannerHeaderPlate {
    flex: 0 0 auto;
}

.bannerHeaderInfo {
    display: inline-block;
    flex: 1 1 auto;
    overflow: hidden;
    min-width: 0;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bannerHeaderAttrList {
    display: none;
    @include media-breakpoint-up(md) {
        flex: 1;
        justify-content: flex-end;
        display: flex;
        column-gap: 15px;
    }
}

.expandCollapseButton {
    z-index: calc(var(--z-index-above-normal) + 1);
}

.collapseContent {
    padding: 20px 0;
    border-top: 1px solid var(--color-primary-20);
}

.collapseContentInner {
    display: grid;
    align-items: start;
    gap: 20px;
    @include media-breakpoint-up(lg) {
        justify-content: space-between;
        grid-template-columns: auto auto;
    }
}

.bannerDrawerAttrList {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.bottomLinks {
    flex-wrap: wrap;
    margin-top: 20px;
    display: flex;
    column-gap: 20px;
    row-gap: 10px;
}

.expertToggle {
    position: absolute;
    top: -23px;
    right: 10px;

    @include media-breakpoint-up(lg) {
        right: var(--grid-gutter-width);
    }
}

.expertToggleText {
    background-color: var(--color-primary-10);
}
