.wrapper {
    background: var(--color-primary-20);
    color: var(--color-primary);
    font-weight: var(--font-weight-semi-bold);
    border-radius: 4px;
    padding: 3px 10px;
    margin-right: 5px;
    position: relative;
    flex-shrink: 0;
}

.closeBtn {
    composes: cleanButton from global;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-40);
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
}

.closeIcon {
    width: 10px;
    path {
        stroke: var(--color-primary);
    }
}

.tagContent {
    display: flex;
    align-items: center;
}

.tagIcon {
    margin-right: 10px;
    width: 24px;
    path {
        stroke: var(--color-primary);
    }
}

.tagText {
    white-space: nowrap;
}
