@import 'config/bundle';

.buttonWrapper {
    position: absolute;
    top: calc(100% - 12px);
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.button {
    background-color: var(--color-white);
    padding: 6px 13px;
    border-radius: 9999px;
    font-size: 11px;
    color: var(--color-black);
    font-weight: var(--font-weight-semi-bold);
    box-shadow: var(--shadow-border-15-full-primary);
    text-transform: uppercase;
}

.buttonCollapsed {
    composes: button;
}
