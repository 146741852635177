.item {
    display: flex;
}

.icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;

    path {
        stroke: var(--color-primary);
    }
}

.mainText {
    font-weight: var(--font-weight-semi-bold);
}

.secondaryText {
    color: var(--color-primary);
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid var(--color-primary-40);
}
.resultPageText {
    color: var(--color-primary);
}
