.modalContent {
    text-align: center;
    margin-top: 20px;
}
.icon {
    width: 150px;
    height: 150px;
}
.formContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    margin-top: 30px;
}
