@import 'config/bundle';

.page {
    display: grid;
    min-height: calc(var(--vh, 1vh) * 100);
    grid-template-columns: auto;
    grid-template-rows: var(--layout-header) 1fr auto;
    grid-template-areas:
        'header'
        'main'
        'footer';

    @include media-breakpoint-up(lg) {
        min-height: 100vh;
        grid-template-columns: var(--layout-aside) 1fr;
        grid-template-areas:
            'nav header'
            'nav main'
            'nav footer';
    }
}

.header {
    grid-area: header;
    position: fixed;
    width: 100%;
    top: 0;
    height: var(--layout-header);
    z-index: var(--z-index-high);
    color: var(--color-white);
    background-color: var(--color-primary);
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        width: calc(100% - var(--layout-aside));
        right: 0;
        background-color: var(--color-white);
        z-index: var(--z-index-header);
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0.5px;
        left: 0;
        box-shadow: var(--shadow-border-15-primary-20);
    }
}

@include media-breakpoint-up(lg) {
    .aside {
        background-color: var(--color-primary);
        position: fixed;
        z-index: var(--z-index-high);
        top: 0;
        width: var(--layout-aside);
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

.main {
    grid-area: main;
    position: relative;
}

.content {
    position: relative;
    margin-bottom: calc(var(--grid-gutter-half) + 10px);
    min-height: min(80vh, 600px);
}

.footer {
    grid-area: footer;
}

.loader {
    z-index: var(--z-index-loader);
}

.stickyContent {
    z-index: calc(var(--z-index-low) + 1);
    position: sticky;
    top: var(--layout-header);
}
