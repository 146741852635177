.root {
    position: absolute;
    width: 100%;
    bottom: 52px;

    padding: 0 1rem;
    color: var(--color-white);

    .label {
        font-size: 13px;
        margin-bottom: 10px;
    }
}
