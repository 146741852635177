@import 'config/bundle';
.item {
    margin-bottom: 10px;
    background-color: var(--color-white-dimmed);
    border: 1px solid var(--color-line);
}

.itemRow {
    display: flex;
    column-gap: 20px;
    align-items: center;
    padding: 20px 20px 10px 20px;
}

.noProduct {
    padding: 20px;
}

.product {
    flex: 1;
}

.price {
    color: var(--color-primary);
    text-align: right;
}

.alreadyCredited {
    opacity: 0.5;
}

.bottomSection {
    padding: var(--box-padding);
    margin-bottom: 10px;
}

.accordionIcon {
    transition: transform var(--easing-primary) var(--timing-normal);
    width: 24px;
    path {
        stroke: var(--color-primary);
    }
}

.accordionButton {
    composes: cleanButton from global;
    position: relative;
    padding: 10px 20px;
    color: var(--color-primary);
    font-weight: var(--font-weight-semi-bold);
    font-size: 14px;
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid var(--color-line);

    &.isOpen {
        border-bottom: 1px solid var(--color-line);
        .accordionIcon {
            transform: rotate(90deg);
        }
    }
}

.accordion {
    display: flex;
    flex-direction: column;
}
.stockPriceWrapper {
    width: 100px;
}
