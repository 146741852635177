@import 'config/bundle';

.dynamicFooterWidgetContainer {
    background: var(--bg-color);
    padding: var(--grid-gutter-half) 0;
}

.dynamicFooterWidget {
    composes: container-fluid from global;
}
