.contentTable {
    margin-bottom: 10px;
    border-top: 1px solid var(--color-line);
    tr:last-child {
        border-bottom: none;
    }
}

.carInfoWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
