@import 'config/bundle';

.container {
    position: fixed;
    top: calc(var(--layout-header) + 10px);
    right: 15px;
    z-index: var(--z-index-high);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;

    @include media-breakpoint-between(sm, xs) {
        width: 100%;
    }
}
