.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--white-1000, #fff);
}

.toggle {
    margin-top: 5px;
}

.inputWrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.inputText {
    margin-top: 8px;
}

.input {
    width: 160px;
    gap: 10px;
}

.inputDate,
.input {
    padding: 14px 50px 14px 10px;
    margin-bottom: 20px;

    border-radius: 4px;
    border: 1px solid #c2c2c2;
    background: var(--white-1000, #fff);

    &.withError {
        margin-bottom: 0;
    }
}

.datePicker {
    width: 144px;
}
