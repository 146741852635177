@import 'config/bundle';

.modalWrapper {
    display: flex;
    justify-content: center;
}

.modalChildren {
    flex: 1;
    overflow-x: visible;
    padding: 30px calc(var(--grid-gutter-width) / 2) 50px;

    @include media-breakpoint-up(sm) {
        padding: 30px var(--grid-gutter-width) 50px;
    }
}
