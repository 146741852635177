@import 'config/bundle';

.mainNav {
    display: none;
    position: fixed;
    top: var(--layout-header);
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-white);
    overflow-y: auto;
    z-index: var(--z-index-navigation);
    height: calc(var(--vh, 1vh) * 100 - var(--layout-header));

    @include media-breakpoint-up(lg) {
        display: block;
        flex-direction: column;
        color: var(--color-white);
        background-color: transparent;
        position: static;
        transform: none !important;
        opacity: 1 !important;
        flex: 1 0;
        height: 100%;
    }
}

.visible {
    display: block;
}

.mainNavScrollArea {
    @include media-breakpoint-up(lg) {
        padding-bottom: calc(2rem + 57px + 30px);
        height: calc(100vh - 192px);
        display: flex;
        flex-direction: column;

        /* Style the scrollbar to so windows machines done have ugly grey scrollbars on blue background */
        &::-webkit-scrollbar {
            width: 12px;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: rgba(var(--color-code-black), 0.3);
        }
    }
}

.internal {
    @include media-breakpoint-up(lg) {
        padding-bottom: max(env(safe-area-inset-bottom), 50px);
    }
}

.mainNavList {
    list-style: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(lg) {
        flex: 1;
        overflow-x: auto;
    }
}
