@import 'config/bundle';

$badge-offset: -4px;

.disc {
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.badge {
    position: absolute;
    top: $badge-offset;
    right: $badge-offset;
}
