@import 'config/bundle';

.serviceDropdown {
    position: fixed;
    z-index: calc(var(--z-index-navigation) + 1);
    top: var(--layout-header);
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform var(--timing-slow) var(--easing-custom), opacity var(--timing-slow) var(--easing-custom);
    height: calc((var(--vh, 1vh) * 100) - var(--layout-header));
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    pointer-events: none;

    @include media-breakpoint-up(lg) {
        transform-origin: top right;
        transform: translateY(0) scale(0.9);
        transition-timing-function: var(--easing-primary);
        transition-duration: var(--timing-quick);
        position: absolute;
        top: 100%;
        left: unset;
        right: -1px;
        width: 250px;
        box-shadow: var(--shadow-focus);
        background-color: var(--color-primary-10);
        height: auto;
        max-height: calc((var(--vh, 1vh) * 100) - var(--layout-header) + 1px);
    }
}

.open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;

    @include media-breakpoint-up(lg) {
        transform: translateY(0) scale(1);
        padding-bottom: 0;
    }
}

@include media-breakpoint-up(lg) {
    .back {
        display: none;
    }
}

.scroller {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    height: 100%;
    padding-bottom: max(env(safe-area-inset-bottom), 50px);

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }
}

.list {
    composes: cleanList from global;
}

@include media-breakpoint-up(lg) {
    .item:last-child .link {
        border-bottom: 0;
    }
}

.link {
    composes: cleanButton from global;
    display: flex;
    width: 100%;
    min-height: 80px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-black-30);
    font-size: 13px;
    line-height: 21px;
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-primary);
    padding: 10px 30px;
    padding-left: max(env(safe-area-inset-left), 30px);
    padding-right: max(env(safe-area-inset-right), 30px);

    &:hover {
        background-color: var(--color-primary-10);
        color: var(--color-primary);
    }

    @include media-breakpoint-up(lg) {
        font-weight: var(--font-weight-regular);
        padding: 10px 20px;
        border-bottom: 1px solid var(--color-primary-20);
        min-height: 50px;

        &:hover {
            background-color: var(--color-primary-20);
        }
    }
}

.badge {
    margin-left: 10px;
    flex-shrink: 0;
    font-weight: var(--font-weight-bold);
}
