@import 'config/bundle';

.button {
    composes: cleanButton from global;
    background-color: var(--color-orange);
    height: 100%;
    border-top-right-radius: 4px;
    padding: 10px 20px 10px 15px;
    display: flex;
    align-items: center;
    color: var(--color-white-dimmed);
    font-weight: var(--font-weight-semi-bold);
}

.buttonInputBlur {
    composes: button;
    border-bottom-right-radius: 4px;
}

.buttonInputFocus {
    composes: button;
    @include media-breakpoint-down(lg) {
        border-bottom-right-radius: 4px;
    }
}

.icon {
    width: 24px;
    height: 24px;

    path {
        stroke: var(--color-white-dimmed);
    }
}

.text {
    display: none;
    margin-left: 10px;
    @include media-breakpoint-up(lg) {
        display: block;
    }
}
