@import 'config/bundle';

.toggler {
    composes: cleanButton from global;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    width: var(--layout-header);
    position: relative;
    font-size: 9.5px;
    line-height: 15px;
    text-align: center;
    font-weight: var(--font-weight-semi-bold);
    border-right: 1px solid var(--color-primary-80);
    margin-left: min(env(safe-area-inset-left), 30px);

    @include media-breakpoint-up(lg) {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 0;
        width: 0;
    }
}

.icon {
    margin-top: -3px;
}
.path {
    stroke: currentColor;
}
