@import 'config/bundle';

.banner {
    background-color: var(--color-green-30);
    border: 1px solid var(--color-green);
    overflow: hidden;
    padding: 0 24px;
    z-index: calc(var(--z-index-low) + 1);

    @include media-breakpoint-up(lg) {
        padding: 0 29px;
    }
}

.inner {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userInfo {
    flex: 1;
    margin-right: 30px;
}

.userInfoTop {
    display: flex;
    align-items: center;
}

.info {
    margin: 0 30px 0 0;
    font-size: 13px;
    align-self: flex-start;
    &:last-child {
        margin-right: 0;
    }
}
.infoAddress,
.internalInformation,
.infoPhone {
    display: none;
}

@include media-breakpoint-up(md) {
    .infoPhone {
        display: block;
    }
}

@include media-breakpoint-up(xl) {
    .infoAddress,
    .internalInformation {
        display: block;
    }
}

.title {
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 10px;
}
.value {
    font-family: var(--font-monospace);
}

.commentsSection {
    display: none;
    @include media-breakpoint-up(xl) {
        margin-top: 10px;
        display: block;
    }
}

.comments {
    display: flex;
}

.comment {
    font-size: 13px;
    line-height: 18px;
    &:first-child {
        margin-right: 30px;
    }
}

