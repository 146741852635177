@import 'config/bundle';

.sorted {
    composes: cleanList from global;
    margin: 20px 30px 30px;
    margin-left: max(env(safe-area-inset-left), 30px);
    margin-right: max(env(safe-area-inset-right), 30px);
    display: grid;
    gap: 30px 0;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    @include media-breakpoint-up(lg) {
        margin: 0 60px 50px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

.letter {
    font-size: 22px;
    line-height: 28px;
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-primary);
}

.companys {
    composes: cleanList from global;
}

.link {
    display: block;
    word-wrap: break-word;
    padding: 5px 10px 5px 0;
    font-size: 13px;
    line-height: 21px;
    font-weight: var(--font-weight-regular);
}

.expertLink {
    composes: link;
    color: var(--color-green);
}
