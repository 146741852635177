@import 'config/bundle';

.standardNav {
  list-style: none;
  padding: 0;
  margin: 0;

  @include media-breakpoint-up(lg) {
    display: block;
    margin: 0 60px 110px;
  }
}

.grid {
  @include media-breakpoint-up(lg) {
    display: grid;
    gap: 70px 30px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}

.small {
  @include media-breakpoint-up(lg) {
    gap: 30px 30px;
  }
}

.open {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
