@import 'config/bundle';

.root {
    overflow-x: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px 20px;
    min-height: 40px;
    flex-wrap: wrap;
    align-items: left;
    padding: 5px var(--grid-gutter-half);
    background-color: var(--color-white);

    position: relative;
    z-index: var(--z-index-low);

    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 1.5px;
        left: 0;
        z-index: var(--z-index-very-low);
        box-shadow: var(--shadow-border-15-primary-20);
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &.isCarInfoBannerPresent {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.expertModeLayout {
    margin-right: 100px;
}
.profileInfo {
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 3px 10px;
    margin-left: auto;
    color: var(--color-black-50);
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
    }
}

.blueWrapper {
    background-color: var(--color-primary-10);
}

.breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.breadcrumb {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
        &:nth-last-child(-n + 2):not(:first-child)::before {
            content: '/';
            display: inline-block;
            margin: 0 4px;
            color: var(--color-primary);
        }

        &:not(:nth-last-child(-n + 3)) {
            display: none;
        }

        &:nth-last-child(3) .link {
            &::before {
                content: '...';
            }

            .linkText {
                display: none;
            }
        }
    }

    @include media-breakpoint-between(md, xxl) {
        &:nth-last-child(-n + 3):not(:first-child)::before {
            content: '/';
            display: inline-block;
            margin: 0 4px;
            color: var(--color-primary);
        }

        &:not(:nth-last-child(-n + 4)) {
            display: none;
        }

        &:nth-last-child(4) .link {
            &::before {
                content: '...';
            }

            .linkText {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        &:not(:first-child)::before {
            content: '/';
            display: inline-block;
            margin: 0 4px;
            color: var(--color-primary);
        }
    }

    @include media-breakpoint-up(xl) {
        max-width: auto;
    }
}

.link {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: var(--font-weight-regular);
}

.disabledLink {
    composes: link;
    color: var(--color-black-50);
}

.linkText {
    overflow: hidden;
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-up(md) {
        max-width: 250px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 350px;
    }
}
