.counter {
    display: flex;
    align-items: center;
    color: var(--color-white);
    font-size: 13px;

    &Urgent .digit {
        background-color: var(--color-orange-70);
        border: 1px solid var(--color-white);
    }
}

.digit {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--color-primary-80);
}

.divider {
    display: inline-block;
    margin: 0 5px;
}
