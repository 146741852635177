@import "config/bundle";

.mainNavBackButtonExpertToggle {
  position: absolute;
  right: 20px;

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    position: relative;
    margin-right: 10px;
  }
}