@import 'config/bundle';

.footer {
    background-color: var(--color-primary-10);
    color: var(--color-black);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.moduleListFooter {
    margin-top: 0;
}

.content {
    width: 100%;
    display: grid;
    gap: var(--grid-gutter-half);

    @include media-breakpoint-up(md) {
        grid-template-columns: 2fr 2fr 1fr;
        padding: var(--grid-gutter-width);
    }
}

.sectionTitle {
    margin-bottom: 10px;
}

.link {
    composes: link from global;
    font-size: 13px;
    display: block;
    line-height: 24px;
}

.singUpWrapper {
    background-color: var(--color-primary-5);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: var(--grid-gutter-width);
    padding-right: var(--grid-gutter-width);
    padding-top: var(--grid-gutter-width);

    @include media-breakpoint-up(md) {
        padding: var(--grid-gutter-width);
    }
}
