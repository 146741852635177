@import 'config/bundle';

.errorBox {
    margin-bottom: 10px;
}

.title {
    margin-bottom: 15px;
    margin-left: 5px;
    color: var(--body-color);
    letter-spacing: 0.6px;
}

.list {
    composes: cleanList from global;
}

.listItem {
    color: var(--color-primary);
    padding: 15px 10px;
    position: relative;
}

.lisItemInactive {
    composes: listItem;
    &::after {
        content: '';
        display: block;
        width: calc(100% - 10px);
        height: 0px;
        border-bottom: 1px solid var(--color-primary-20);
        position: absolute;
        left: 5px;
        top: 100%;
    }

    &:first-child::before {
        content: '';
        display: block;
        width: calc(100% - 10px);
        height: 0px;
        border-top: 1px solid var(--color-primary-20);
        position: absolute;
        left: 5px;
        top: 0;
    }
}

.lisItemActive {
    cursor: pointer;
    composes: listItem;
    background-color: var(--color-primary-10);
    border-radius: 4px;
    box-shadow: var(--shadow-focus);
    @include media-breakpoint-up(lg) {
        background-color: var(--color-white-dimmed);
    }
}

.clearHistoryBtn {
    composes: cleanButton from global;
    margin-top: 10px;
    display: block;
    width: 100%;
    color: var(--color-primary);
    padding: 10px 15px 0 15px;

    &:hover {
        text-decoration: underline;
    }
}
