@import "config/bundle";

.navLink {
  border-bottom: 1px solid var(--color-black-30);

  &:hover {
    background-color: var(--color-primary-10);
    color: var(--color-primary);
  }

  @include media-breakpoint-up(lg) {
    min-height: initial;
    border-bottom: 0;

    &:hover {
      background-color: initial;
      color: var(--color-primary-60);
    }
  }
}

.navLinkAnchor {
  font-size: 13px;
  line-height: 21px;
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-primary);

  composes: cleanButton from global;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 80px;
  padding: 10px max(env(safe-area-inset-right), 30px) 10px max(env(safe-area-inset-left), 30px);

  @include media-breakpoint-up(lg) {
    min-height: auto;
  }
}

.small {
  @include media-breakpoint-up(lg) {
    font-size: 13px;
    line-height: 21px;
    font-weight: var(--font-weight-regular);
    padding: 5px 0;
  }
}

.large {
  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 22px;
    padding: 5px 0 10px;
  }
}
